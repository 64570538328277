// // Global theme variables
// $margin-x:                              ($spacer * 2); // Left & Right
// $margin-y:                              ($spacer * 2); // Top & Bottom
// $padding-x:                             ($spacer * 2); // Left & Right
// $padding-y:                             ($spacer * 2); // Top & Bottom

// $main-breakpoint:                       md;
// $font-family-icon:                      'Font Awesome 5 Pro';
// $font-weight-icon:                      400;


// Breadcrumbs
// $breadcrumb-height:                     3rem;


// // Swatches
// $swatch-padding-y:                      .25rem;
// $swatch-padding-x:                      .4rem;
// $swatch-width:                          ($spacer * 2.5);
// $swatch-height:                         ($spacer * 2);
// $swatch-image-width:                    8.125rem;
// $swatch-image-height:                   8.125rem;
// $swatch-font-size:                      $font-size-base;


// // Slick gallery
// $photo-hover-cursor:                    zoom-in;
// $photo-loader-background:               $white;
// $photo-slider-next-icon:                '\f054';
// $photo-slider-prev-icon:                '\f053';
// $photo-slider-arrow-bg:                 transparent;
// $photo-slider-arrow-color:              $black;
// $photo-slider-arrow-size:               2rem;

// $photo-thumbs-next-icon:                '\f054';
// $photo-thumbs-prev-icon:                '\f053';
// $photo-thumbs-arrow-bg:                 transparent;
// $photo-thumbs-arrow-color:              $black;
// $photo-thumbs-arrow-size:               1.5rem;

// $slick-dots-background:                 $gray-200;
// $slick-dots-background-active:          $primary;
// $slick-dots-width:                      12px;
// $slick-dots-height:                     12px;
// $slick-dots-border-radius:              50%;

// $player-button-width:                   4rem;
// $player-button-height:                  4rem;
// $player-button-background:              $primary;
// $player-button-border-radius:           50%;
// $player-button-color:                   $white;

// $photo-lightbox-max-width:              1200px;
// $photo-lightbox-max-height:             85vh;
// $photo-lightbox-background:             rgba(0, 0, 0, 0.3);
// $photo-lightbox-next-icon:              '\f054';
// $photo-lightbox-prev-icon:              '\f053';
// $photo-lightbox-arrow-bg:               transparent;
// $photo-lightbox-arrow-color:            $white;
// $photo-lightbox-arrow-size:             2rem;
// $photo-lightbox-close-icon:             '\f137';
// $photo-lightbox-close-bg:               transparent;
// $photo-lightbox-close-color:            $white;
// $photo-lightbox-close-size:             2.5rem;


// // Product
// $spinbox-width:                         ($spacer * 4) ;


// // Offcanvas
// $zindex-offcanvas:                      1070;
// $zindex-offcanvas-backdrop:             1060;

// $offcanvas-heading-bg:                  $primary;
// $offcanvas-heading-color:               $white;
// $offcanvas-bg:                          $white;
// $offcanvas-backdrop-bg:                 $black;
// $offcanvas-backdrop-opacity:            .5;

// $offcanvas-max-width:                   25rem;
// $offcanvas-font-size:                   $h5-font-size;

// $offcanvas-fade-transform:              translateX(-100%);
// $offcanvas-show-transform:              none;
// $offcanvas-transition:                  transform .2s ease-out;


// // Buttons
// $btn-primary:                           $primary;
// $btn-secondary:                         $secondary;
// $btn-search:                            $primary;
// $btn-addtocart:                         $teal;
// $btn-cta:                               $teal;
// $btn-wishlist:                          $light;
// $btn-compare:                           $light;
// $btn-login:                             $primary;
// $btn-spinbox:                           $input-border-color;
// $btn-back:                              $gray-600;


// Icons
// $enable-fontawesome-light:              true;
// $enable-fontawesome-regular:            true;
// $enable-fontawesome-solid:              true;
// $enable-fontawesome-brands:             true;
// $enable-fontawesome-duotone:            false;

// // Review
// $review-icon-default:                   '\f005';
// $review-icon-hover:                     '\f005';
// $review-icon-color:                     $yellow;

//cart counter
// $cart-counter-background: $primary;
// $cart-counter-foreground: $white;

// $cart-counter-mobile-width: 1.2rem;
// $cart-counter-mobile-height: 1.2rem;
// $cart-counter-mobile-left: initial;
// $cart-counter-mobile-top: initial;
// $cart-counter-mobile-bottom: -0.2rem;
// $cart-counter-mobile-right: -0.8rem;
// $cart-counter-mobile-fontsize: 0.6rem;

// $cart-counter-desktop-width: 1.2rem;
// $cart-counter-desktop-height: 1.2rem;
// $cart-counter-desktop-left: initial;
// $cart-counter-desktop-top: initial;
// $cart-counter-desktop-bottom: 0.2rem;
// $cart-counter-desktop-right: -0.5rem;
// $cart-counter-desktop-fontsize: 0.6rem;
